import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { css } from '@emotion/react'
import { FaInstagram } from "react-icons/fa"


const Header = ({ siteTitle }) => (
  <header>
    <div className="container" css={navBar}>
      <h1 style={{ margin: 0 }}>
        <Link to="/">
          <span role="img" aria-label="press hand">👇</span> {siteTitle}
        </Link>
      </h1>

      <a href="https://www.instagram.com/pause.btn/" css={insta} aria-label="go to instagram"><FaInstagram /></a>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header


const navBar = css`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  height: 60px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-size: 1.2rem;
  }

  a {
    color: #111;
    text-decoration: none;
  }
`

const insta = css`
`