import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

import { css } from '@emotion/react'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />

      <div className="container">
        <main>{children}</main>

        <div css={banner}>
          <h3>Slow down!</h3>
          <p>Disclaimer: we share those techniques for informational purpose only. This is not therapy, or medical advice of any kind, and you should always practice relaxation in a safe environment and in good health conditions. We decline all responsibility.</p>
        </div>

        <footer css={footer}>
          <h4>Pause Button</h4>
          <p>We help you take a break in your day, and center your awareness back to yourself to regain health strength and hapiness.</p>
          © {new Date().getFullYear()} Pause Button. Made with ❤️ from Switzerland.
        </footer>
      </div>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const footer = css`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 50px;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  h4 {
    margin-bottom: 10px;
  }

  p {
    margin: 0 0 10px;
    max-width: 300px;
    line-height: 1.3;
  }
`

const banner = css`
  margin-top: 40px;
  background-color: #F9F5F1;
  border-radius: 6px;
  padding: 30px;

  h3 {
    margin-bottom: 5px;
  }

  p {
    margin: 0;
    opacity: .6;
  }
`